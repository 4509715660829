<template>
  <el-form
    size="small"
    class="strategy-form com-form"
    ref="formRef"
    :rules="formRules"
    :model="formModel"
    label-width="200px"
  >
    <div class="dp-flex align-item-c">
      <el-form-item label="策略名称：" prop="name">
        <el-input
          v-model="formModel.name"
          placeholder="请输入策略名称"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-popover popper-class="" placement="bottom-end" trigger="hover">
        <div v-html="whatStrategy"></div>
        <h3 class="hover-theme-color" slot="reference">什么是策略</h3>
      </el-popover>
    </div>
    <el-form-item label="选择策略类型：" prop="type">
      <el-radio-group class="dp-flex flex-d-c" v-model="formModel.type">
        <el-radio
          class="mb-10"
          :disabled="item.disabled"
          v-for="(item, index) in strategyTypes"
          :key="index"
          :label="item.id"
          >{{ item.name }}<span v-if="item.id == 2">[推荐]</span></el-radio
        >
      </el-radio-group>
    </el-form-item>
    <el-form-item label="策略应用于：" prop="useto">
      <span v-if="formModel.type == 1">
        策略应用于所有的广告账号
      </span>
      <template v-if="formModel.type == 2">
        <el-radio-group v-model="formModel.useto">
          <el-radio
            v-for="(item, index) in useRanges"
            :key="index"
            :label="item.id"
            ><span @click="openAdcampaigns(data.campaigns)">{{
              item.name
            }}</span></el-radio
          >
        </el-radio-group>
        <p v-for="(cam, cami) in data.campaigns" :key="cami" class="name">
          {{ cam.name }}
        </p>
      </template>
    </el-form-item>
    <el-form-item v-if="showSubmitBtn">
      <el-button>取消 </el-button>
      <el-button
        :disabled="!formModel.name"
        type="primary"
        @click="handleConfirmCusStrategy"
        >确定</el-button
      >
    </el-form-item>
    <!-- 广告系列弹窗 -->
    <ad-campaigns-dialog
      :adcampaignsVisible="adcampaignsVisible"
      @close-dialog="adcampaignsVisible = false"
      :selectedCampaigns="data.campaigns"
      @select-change="handleSelectChange"
    ></ad-campaigns-dialog>
  </el-form>
</template>

<script>
import adCampaignsDialog from "@/views/autoRules/components/adCampaignsDialog";
export default {
  components: {
    adCampaignsDialog
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showSubmitBtn: { type: Boolean, default: () => false }
  },
  data() {
    return {
      formModel: { name: "", type: 2, useto: 0 },
      formRules: {
        name: {
          required: true,
          message: "请输入策略名称",
          trigger: ["change", "blur"]
        },
        type: {
          required: true,
          message: "请选择策略类型",
          trigger: ["change"]
        }
      },
      whatStrategy: ` <div
          style="width: 300px; font-size: 12px; color: rgb(157, 160, 163); text-indent: 2em; line-height: 22px;"
        >
          <div>
            有时候，对于类似的场景我们会设置多条规则并使用不同的判断条件。比如需要监控广告组的CPA异常情况，我们会创建一条监控历史投放CPA的规则，还会再创建一条监控每日投放CPA的规则，这两条规则可以在一个策略中集中管理。
          </div>
          <div>
            在策略上设置可应用的广告系列范围，策略下的所有自动规则，可应用的广告系列都跟随策略。
          </div>
        </div>`,
      strategyTypes: [
        { id: 2, name: "广告系列策略" },
        { id: 1, name: "广告账户级策略" },
        { id: 0, name: "预算池策略（暂未开放）", disabled: true }
      ],
      useRanges: [{ id: 0, name: "广告系列" }],
      // 广告系列
      adcampaignsVisible: false
      // selectedCampaigns: []
    };
  },
  methods: {
    handleSelectChange(val) {
    //  // console.log('form-',val) 
      this.$emit("select-change", val);
    },
    handleConfirmCusStrategy() {     
      this.$emit("confirm-cus-strategy", this.formModel);
    },
    // 广告系列弹窗
    openAdcampaigns(selectedCampaigns) {
      this.adcampaignsVisible = true;
    }
  },
  mounted() {
    this.formModel.name = this.data && this.data.name ? this.data.name : "";
    this.formModel.type = this.data && this.data.type ? this.data.type : 2;
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.strategy-form {
  h3 {
    margin-left: 15px;
    margin-bottom: 22px;
    color: rgb(112, 112, 112);
    cursor: pointer;
  }
  .name {
    color: rgba(0, 0, 0, 0.45)!important;
    line-height: 1.5715;
  }  
}
</style>