<template>
  <el-dialog
    custom-class="cus-dialog common-dialog"
    :visible="adcampaignsVisible"
    title="选择广告系列"
    @close="handleClose"
    :append-to-body="true"
  >
    <cus-table
      v-if="adcampaignsVisible"
      ref="cusTableRef"
      :tableParams="tableParams"
      :tableData="tableData"
      :colData="colData"
      :isInfiniteScroll="true"
      :infiniteScrollImmediate="false"
      :infiniteScrollDisabled="infiniteScrollDisabled"
      :appendData="appendData"
      @load-more="loadMore"
      @select-change="handleSelectChange"
    >
      <template v-slot:cusheader="{ scope }">
        <template v-if="scope.column.sortable">
          <el-tooltip
            effect="dark"
            :content="
              scope.column.order == 'ascending'
                ? '点击降序'
                : scope.column.order == 'descending'
                ? '取消排序'
                : '点击升序'
            "
            placement="top"
          >
            <span>{{ scope.column.label }}</span>
          </el-tooltip>
        </template>
      </template>
      <template v-slot:colslot="{ scope, prop }">
        <div v-if="prop == 'created_time'">
          {{ scope.row[prop].split("T")[0] }}
        </div>
      </template>
      <template v-slot:cusappend="{ data }">
        <p class="text-a-c" style="line-height:50px;" v-if="data.loading">
          努力加载中...
        </p>
        <p class="text-a-c" style="line-height:50px;" v-if="data.nomore">
          抱歉，没有更多了!
        </p>
      </template>
    </cus-table>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cusTable from "@/views/components/cusTable";
import { getCampaigns } from "@/api/autoRules";
export default {
  components: {
    cusTable
  },
  props: {
    adcampaignsVisible: {
      type: Boolean,
      default: () => false
    },
    selectedCampaigns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pageAfter: "",
      limit: 10,
      tableParams: {
        className: "cus-table",
        border: true,
        selection: true,
        height: "100%"
      },
      tableData: [],
      colData: [
        { label: "广告系列名称", prop: "name" },
        {
          label: "日预算",
          prop: "daily_budget"
        },
        { label: "总开支", prop: "spend_cap", sortable: true },
        { label: "创建日期", prop: "created_time", sortable: true }
      ],
      mulCampaigns: [],
      // 表格最后一行之后的内容-加载中-没有更多
      appendData: {
        loading: false,
        nomore: false
      },
      total: 50
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.appendData.loading || this.appendData.nomore;
    },
    cusCampaignObj() {
      return {
        selectedCampaigns: this.selectedCampaigns,
        tableData: this.tableData
      };
    }
  },
  methods: {
    handleSelectChange(val) {
      this.mulCampaigns = val;
    },
    // 获取当前广告账号下的广告系列
    getCampaigns() {
      this.appendData.loading = true;
      let params = {
        account_no: this.$store.getters.fbAccountId,
        after: this.pageAfter,
        page_length: this.limit
      };
      // console.log("getCampaigns-params", params);
      getCampaigns(params).then(res => {
        this.appendData.loading = false;
        if (res.code == 200) {
          if (res.data.data && res.data.data.length) {
            this.tableData.push(...res.data.data);
            this.pageAfter = res.data.paging.after;
            this.total = res.data.summary ? res.data.summary.total_count : 50;
          }
        }
      });
    },
    loadMore() {
      // console.log("load1");
      if (!this.appendData.nomore) {
        // console.log("load2");
        this.getCampaigns();
      }
    },
    handleClose() {
      this.$emit("close-dialog");
      this.$refs.cusTableRef.$refs.tableRef.clearSelection();
      this.pageAfter = "";
      this.tableData = [];
    },
    handleConfirm() {
      this.$emit("select-change", this.mulCampaigns);
      this.$emit("close-dialog");
    }
  },
  watch: {
    adcampaignsVisible: {
      handler(val) {
        if (val) {
          this.getCampaigns();
        }
      }
    },
    tableData: {
      handler(val) {
        if (val && val.length) {
          if (val.length >= this.total) {
            this.appendData.nomore = true;
          }
        } else {
          this.appendData.nomore = false;
        }
      },
      deep: true
    },
    cusCampaignObj: {
      handler(val) {
        if (
          val.selectedCampaigns &&
          val.selectedCampaigns.length &&
          this.tableData &&
          this.tableData.length
        ) {
          val.selectedCampaigns.forEach(item => {
            let obj = this.tableData.find(r => r.id == item.id);
            if (obj) {
              this.$refs.cusTableRef.$refs.tableRef.toggleRowSelection(
                obj,
                true
              );
            }
          });
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.cus-dialog {
  width: 900px;
  .el-dialog__header {
    @extend .dp-flex;
  }
  .el-dialog__title {
    @extend .text-a-c;
  }
  .el-dialog__footer {
    @extend .text-a-c;
  }
  .cus-table.el-table .el-table__row:nth-child(2n) {
    background: initial !important;
  }
  .cus-table.el-table .el-table__row:nth-child(2n-1) {
    background: #f8f8fa;
  }
}
</style>